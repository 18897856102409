{
  "en": {
    "20848859": "Blackcliff Slasher",
    "33330467": "Elemental Mastery",
    "37147251": "Solar Pearl",
    "43015699": "TBD",
    "54857595": "Bane of Storm and Tide",
    "83115355": "Maiden Beloved",
    "85795635": "Focus",
    "88505754": "Kaedehara Kazuha",
    "135182203": "Bane of Storm and Tide",
    "143051931": "Xiphos' Moonlight",
    "147298547": "Wanderer's Troupe",
    "156294403": "Heart of Depth",
    "160493219": "Dark Iron Sword",
    "168956722": "Qiqi",
    "197755235": "Vortex Vanquisher",
    "212557731": "Prayers for Wisdom",
    "231836963": "Primordial Jade Cutter",
    "240385755": "Wavesplitter",
    "246984427": "Bane of Fire and Thunder",
    "262428003": "Prayers to Springtime",
    "270124867": "Protector's Virtue",
    "287454963": "Prayers to the Firmament",
    "288666635": "Spotless Heart",
    "302691299": "Amber Bead",
    "303155515": "Unreturning",
    "310247243": "Kagura's Verity",
    "313300315": "Sapwood Blade",
    "334242634": "Shenhe",
    "339931171": "Press the Advantage",
    "342097547": "Cinnabar Spindle",
    "346510395": "Luxurious Sea-Lord",
    "359484419": "Tulaytullah's Remembrance",
    "368014203": "Sky-ripping Dragon Spine",
    "388272194": "Dori",
    "391273955": "Black Wing",
    "411685275": "Compound Bow",
    "426363739": "Wandering Evenstar",
    "452043283": "Lost Ballade",
    "452357939": "Moonpiercer",
    "453444347": "A Thousand Nights' Dawnsong",
    "454086795": "Key of Khaj-Nisut",
    "479076483": "Cool Steel",
    "481755219": "Blackcliff Pole",
    "486287579": "Residual Heat",
    "500612819": "The Flagstaff",
    "506630267": "Windfall",
    "514784907": "Bane of Flame and Water",
    "521221323": "Protector's Virtue",
    "540938627": "Predator",
    "565329475": "Kunwu's Wyrmbane",
    "566772267": "Gokadaiou Otogibanashi",
    "577103787": "Energy Shower",
    "578575283": "Crescent Pike",
    "586598971": "Full Circle",
    "597991835": "Byakuya Kougetsu",
    "607067963": "Isshin Art Clarity",
    "613846163": "Descension",
    "618786571": "Halberd",
    "623494555": "Decimate",
    "623534363": "Favonius Codex",
    "630452219": "Sakura Saiguu",
    "646032090": "Shikanoin Heizou",
    "646100491": "Lithic Axiom: Unity",
    "650049651": "Windblume Ode",
    "655825874": "Yun Jin",
    "656120259": "Sharpshooter's Oath",
    "680510411": "Whiteblind",
    "688991243": "Calamity Queller",
    "693354267": "Memory of Dust",
    "697277554": "Yanfei",
    "712501082": "Nahida",
    "716252627": "Lithic Spear",
    "729851187": "Glacier and Snowfield",
    "735056795": "Favonius Greatsword",
    "807607555": "Skyward Atlas",
    "824949859": "Dodoventure!",
    "828711395": "Amos' Bow",
    "836208539": "Gilding",
    "850802171": "White Iron Greatsword",
    "855894507": "Berserker",
    "862591315": "Pale Flame",
    "877751435": "Royal Greatsword",
    "902184579": "Forest Regalia",
    "902264035": "Aquila Favonia",
    "902282051": "Cull the Weak",
    "909145139": "Protector's Virtue",
    "930640955": "The Bell",
    "933076627": "Blizzard Strayer",
    "942758755": "Focus",
    "944332883": "Summit Shaper",
    "949506483": "Oceanic Victory",
    "968378595": "Falcon's Defiance",
    "968893378": "Bennett",
    "977648923": "Primordial Jade Greatsword",
    "991968139": "Timeless Dream: Eternal Stove",
    "1006042610": "Kamisato Ayaka",
    "1012170803": "Kagotsurube Isshin",
    "1021898539": "Slingshot",
    "1021947690": "Xiao",
    "1028735635": "Revolutionary Chorale",
    "1049891906": "Cyno",
    "1053433018": "Sucrose",
    "1072884907": "Mappa Mare",
    "1075647299": "Song of Broken Pines",
    "1082448331": "People of the Faltering Light",
    "1089950259": "Skyward Pride",
    "1097898243": "Heavy",
    "1103732675": "Lucky Dog",
    "1113306282": "Mona",
    "1114777131": "Chord",
    "1119368259": "Journey",
    "1130996346": "Xiangling",
    "1133599347": "Strong-Willed",
    "1148024603": "\"The Catch\"",
    "1154009435": "Prototype Starglitter",
    "1163263227": "The Widsith",
    "1163616891": "Frost Burial",
    "1182966603": "Old Merc's Pal",
    "1186209435": "Gambler",
    "1201790667": "A Thousand Floating Dreams",
    "1212345779": "Gladiator's Finale",
    "1217552947": "Honed Flow",
    "1240067179": "Favonius Warbow",
    "1307222899": "Wandering Striker",
    "1319974859": "Vigorous",
    "1321135667": "Lion's Roar",
    "1337666507": "Tenacity of the Millelith",
    "1344953075": "Windfall",
    "1345343763": "Primordial Jade Cutter",
    "1348687251": "Hunter's Path",
    "1383639611": "Tiny Miracle",
    "1388004931": "Skyrider Sword",
    "1390797107": "White Tassel",
    "1395607523": "At the End of the Beast-Paths",
    "1404688115": "The Parting Refrain",
    "1406746947": "Otherworldly Story",
    "1414366819": "Golden Majesty",
    "1437658243": "Serpent Spine",
    "1438974835": "Retracing Bolide",
    "1455107995": "Lost Prayer to the Sacred Winds",
    "1468367538": "Diona",
    "1470442731": "Missive Windspear",
    "1479961579": "Ferrous Shadow",
    "1483922610": "Kujou Sara",
    "1485303435": "Infusion Stinger",
    "1492752155": "Composed",
    "1499235563": "Press the Advantage",
    "1499745907": "Prized Isshin Blade",
    "1499817443": "Verdant Wind",
    "1516554699": "Quartz",
    "1522029867": "Bane of Fire and Thunder",
    "1524173875": "Crimson Witch of Flames",
    "1533656818": "Traveler",
    "1538092267": "Prized Isshin Blade",
    "1541919827": "Bloodstained Chivalry",
    "1545992315": "Justice",
    "1558036915": "Vermillion Hereafter",
    "1562601179": "Viridescent Venerer",
    "1588620330": "Kamisato Ayato",
    "1600275315": "Haran Geppaku Futsu",
    "1608953539": "Harbinger of Dawn",
    "1610242915": "Heritage",
    "1628928163": "Windblume Wish",
    "1632377563": "Lavawalker",
    "1651985379": "Daylight's Augury",
    "1660598451": "Iwakura Succession",
    "1675079283": "Deepwood Memories",
    "1675686363": "Sacrificial Greatsword",
    "1702903411": "Jinni's Whisper",
    "1706534267": "Blunt Conclusion",
    "1722706579": "Bane of Water and Ice",
    "1745286795": "Samurai Conduct",
    "1745712907": "Watatsumi Wavewalker",
    "1751039235": "Noblesse Oblige",
    "1756609915": "Ocean-Hued Clam",
    "1771603299": "Golden Majesty",
    "1773425155": "Sword of Descension",
    "1789612403": "Echo",
    "1820235315": "Arrowless Song",
    "1836628747": "Rebellious Guardian",
    "1860795787": "Mouun's Moon",
    "1864015138": "Keqing",
    "1873342283": "Thundersoother",
    "1890163363": "Everlasting Moonglow",
    "1901973075": "Polar Star",
    "1921418842": "Noelle",
    "1932742643": "Extinguishing Precept",
    "1934830979": "Undying Admiration",
    "1940821986": "Kuki Shinobu",
    "1940919994": "Hu Tao",
    "1966438658": "Amber",
    "1982136171": "Focus",
    "1990641987": "Sacrificial Sword",
    "1990820123": "Amenoma Kageuchi",
    "1991707099": "Prototype Rancour",
    "1994081075": "Primordial Jade Vista",
    "1997709467": "Primordial Jade Winged-Spear",
    "2006422931": "Lithic Blade",
    "2017033267": "Composed",
    "2025598051": "Windfall",
    "2029582603": "Kaidan: Rainfall Earthbinder",
    "2040573235": "Archaic Petra",
    "2060049099": "Prayers for Illumination",
    "2108574027": "Smashed Stone",
    "2109571443": "Focus",
    "2125206395": "Silver Sword",
    "2149411851": "Golden Majesty",
    "2153521307": "Desert Pavilion",
    "2172529947": "Press the Advantage",
    "2176134843": "Focus",
    "2190368347": "Gash",
    "2191797987": "Adventurer",
    "2195665683": "Sacrificial Fragments",
    "2242027395": "Ebony Bow",
    "2244980899": "Sunken Song of the Sands",
    "2257505883": "Forest Sanctuary",
    "2267978875": "Fruit of Fulfillment",
    "2276480763": "Emblem of Severed Fate",
    "2279290283": "Magic Guide",
    "2297485451": "Rapid Firing",
    "2312640651": "Composed",
    "2317820211": "Infusion Needle",
    "2322648115": "Crush",
    "2324146259": "Hakushin Ring",
    "2340970067": "Seasoned Hunter's Bow",
    "2351445619": "Bygone Azure Teardrop",
    "2359799475": "Akuoumaru",
    "2364208851": "Resolution of Sojourner",
    "2365025043": "Itinerant Hero",
    "2375993851": "Royal Longsword",
    "2383998915": "Watatsumi Wavewalker",
    "2384519283": "Slingshot",
    "2387711994": "Faruzan",
    "2388785242": "Sayu",
    "2400012995": "Sacrificial Bow",
    "2410593283": "Dull Blade",
    "2417717595": "Alley Hunter",
    "2425414923": "Fading Twilight",
    "2433755451": "Rebel's Banner-Hymn",
    "2440850563": "Echoing Ballad",
    "2448629019": "Toukabou Shigure",
    "2466140362": "Venti",
    "2469300579": "Press the Advantage",
    "2470306939": "Rule by Thunder",
    "2474354867": "Favonius Sword",
    "2476346187": "Bane of Flame and Water",
    "2491797315": "Kitain Cross Spear",
    "2504399314": "Yoimiya",
    "2506955778": "Tighnari",
    "2512309395": "Thundering Fury",
    "2521338131": "Prototype Amber",
    "2534304035": "Mistsplitter's Edge",
    "2538235187": "Desert Pavilion Chronicle",
    "2539208459": "Oathsworn Eye",
    "2546254811": "Husk of Opulent Dreams",
    "2556914683": "The Stringless",
    "2587614459": "Frostbearer",
    "2597527627": "The Other Side",
    "2614170427": "Skyrider Greatsword",
    "2646367730": "Beidou",
    "2664629131": "Dragon's Bane",
    "2666951267": "Waster Greatsword",
    "2673337443": "Infusion Arrow",
    "2679781122": "Ganyu",
    "2684365579": "Debut",
    "2691070627": "Forest Sanctuary",
    "2705029563": "Pocket Grimoire",
    "2713453234": "Yae Miko",
    "2743659331": "Rapids",
    "2749508387": "Golden Majesty",
    "2749853923": "Festering Desire",
    "2753539619": "Snow-Tombed Starsilver",
    "2764598579": "The Exile",
    "2792766467": "The Unforged",
    "2796697027": "Beginner's Protector",
    "2824690859": "Mirror Breaker",
    "2832648187": "Royal Bow",
    "2834803571": "Golden Majesty",
    "2848374378": "Yelan",
    "2853296811": "Sky-Piercing Fang",
    "2871793795": "Sharp",
    "2876340530": "Chongyun",
    "2889777514": "Layla",
    "2890909531": "Martial Artist",
    "2893964243": "Archer's Message",
    "2915865819": "Radiance of the Deeps",
    "2918525947": "Thundering Pulse",
    "2935286715": "Royal Spear",
    "2947140987": "The Alley Flash",
    "2948362178": "Collei",
    "2949448555": "Freedom-Sworn",
    "2963220587": "Emerald Orb",
    "3001782875": "Composed",
    "3018479371": "Shanty",
    "3024507506": "Raiden Shogun",
    "3063191787": "End of the Line",
    "3063488107": "Strong Strike",
    "3068316954": "Arataki Itto",
    "3070169307": "Iron Point",
    "3073454867": "Makhaira Aquamarine",
    "3079462611": "Watatsumi Wavewalker",
    "3090373787": "Wine and Song",
    "3092975658": "Candace",
    "3094139291": "Flower of Paradise Lost",
    "3097441915": "Debate Club",
    "3112448011": "Determination",
    "3112679155": "Elegy for the End",
    "3156385731": "Eye of Perception",
    "3159145923": "Wandering Striker",
    "3169209451": "Rust",
    "3176599083": "King's Squire",
    "3192689683": "Frost Burial",
    "3209694115": "Wildling Nightstar",
    "3221566250": "Jean",
    "3230559562": "Wanderer",
    "3235324891": "Staff of Homa",
    "3252085691": "Windfall",
    "3258658763": "Magic Affinity",
    "3265161211": "Infusion Blade",
    "3273999011": "Blackcliff Agate",
    "3277782506": "Fischl",
    "3302787771": "Frost Burial",
    "3305772819": "Guerilla Tactics",
    "3314157803": "Bane of the Soft",
    "3337185491": "Full Draw",
    "3337249451": "Overloaded",
    "3339083250": "Klee",
    "3344622722": "Lisa",
    "3364338659": "Boundless Blessing",
    "3371922315": "Kagura Dance of the Sacred Sakura",
    "3378007475": "Blackcliff Longsword",
    "3400133546": "Gorou",
    "3406113971": "Windfall",
    "3421967235": "Fillet Blade",
    "3439749859": "The Viridescent Hunt",
    "3443142923": "Dragonspine Spear",
    "3447737235": "Blackcliff Warbow",
    "3453611803": "Net Snapper",
    "3456986819": "Dodoco Tales",
    "3464027035": "Prized Isshin Blade",
    "3465493459": "Precise",
    "3500935003": "Thrilling Tales of Dragon Slayers",
    "3513240283": "The Wind Unattained",
    "3532343811": "Deicide",
    "3535784755": "Brave Heart",
    "3541083923": "Gladiator",
    "3555115602": "Thoma",
    "3584825427": "Apprentice's Notes",
    "3587062891": "Lithic Axiom: Unity",
    "3587621259": "The Flute",
    "3600623979": "Hunter's Bow",
    "3608180322": "Diluc",
    "3618167299": "Scholar",
    "3625393819": "Prototype Crescent",
    "3626268211": "Echoes of an Offering",
    "3673792067": "Traveler's Handy Sword",
    "3684723963": "Rainslasher",
    "3685735523": "Wandering Striker",
    "3689108098": "Aloy",
    "3717341819": "Stillwood Moonshadow",
    "3717667418": "Eula",
    "3717849275": "Engulfing Lightning",
    "3719372715": "Twin Nephrite",
    "3722933411": "Prototype Archaic",
    "3755004051": "Favonius Lance",
    "3775299170": "Barbara",
    "3780343147": "One Side",
    "3782508715": "Traveling Doctor",
    "3796702635": "Ever-Changing",
    "3796905611": "The Black Sword",
    "3816664530": "Traveler",
    "3827789435": "Royal Grimoire",
    "3832443723": "Unbending",
    "3836188467": "Reckless Cinnabar",
    "3847143266": "Tartaglia",
    "3850149970": "Nilou",
    "3862787418": "Zhongli",
    "3890292467": "Instructor",
    "3898539027": "Wandering Clouds",
    "3909785739": "Heat Haze at Horizon's End",
    "3914045794": "Sangonomiya Kokomi",
    "3914951691": "Redhorn Stonethresher",
    "3933622347": "Skyward Harp",
    "3949653579": "Mitternachts Waltz",
    "3956074723": "Labyrinth Lord's Instruction",
    "3966753539": "The Cleansing Form",
    "3975746731": "Raven Bow",
    "3995710363": "Wolf's Gravestone",
    "3996017211": "Cull the Weak",
    "3999792907": "Prayers for Destiny",
    "4000770243": "Oppidan Ambush",
    "4007372867": "Primordial Jade Regalia",
    "4022012131": "Press the Advantage",
    "4049410651": "Deathmatch",
    "4055003299": "Skyward Blade",
    "4060235987": "Solar Shine",
    "4080317355": "Courage",
    "4082302819": "Defender's Will",
    "4090429643": "Bloodtainted Greatsword",
    "4103022435": "Iron Sting",
    "4103766499": "Black Tassel",
    "4108620722": "Albedo",
    "4113638323": "Eagle Spear of Justice",
    "4119663210": "Kaeya",
    "4122509083": "Wavebreaker's Fin",
    "4124851547": "Mistsplitter Reforged",
    "4127888970": "Ningguang",
    "4139294531": "Messenger",
    "4144069251": "Shimenawa's Reminiscence",
    "4145306051": "Gilded Dreams",
    "4158505619": "Skyward Spine",
    "4160147242": "Razor",
    "4186179883": "Hamayumi",
    "4193089947": "Katsuragikiri Nagamasa",
    "4197635682": "Xingqiu",
    "4226083179": "Samurai Conduct",
    "4230231107": "Aqua Simulacra",
    "4238339131": "Staff of the Scarlet Sands",
    "4245213187": "Infusion Scroll",
    "4258047555": "Evernight Duet",
    "4260733330": "Rosaria",
    "4267718859": "Recurve Bow",
    "4273845410": "Xinyan",
    "4275754179": "Wolfish Tracker",
    "FIGHT_PROP_BASE_HP": "Base HP",
    "FIGHT_PROP_HP": "HP",
    "FIGHT_PROP_HP_PERCENT": "HP",
    "FIGHT_PROP_BASE_ATTACK": "Base ATK",
    "FIGHT_PROP_ATTACK": "ATK",
    "FIGHT_PROP_ATTACK_PERCENT": "ATK",
    "FIGHT_PROP_BASE_DEFENSE": "Base DEF",
    "FIGHT_PROP_DEFENSE": "DEF",
    "FIGHT_PROP_DEFENSE_PERCENT": "DEF",
    "FIGHT_PROP_BASE_SPEED": "Movement SPD",
    "FIGHT_PROP_SPEED_PERCENT": "Movement SPD",
    "FIGHT_PROP_CRITICAL": "CRIT Rate",
    "FIGHT_PROP_ANTI_CRITICAL": "CRIT RES",
    "FIGHT_PROP_CRITICAL_HURT": "CRIT DMG",
    "FIGHT_PROP_CHARGE_EFFICIENCY": "Energy Recharge",
    "FIGHT_PROP_ADD_HURT": "DMG Bonus",
    "FIGHT_PROP_SUB_HURT": "DMG Reduction",
    "FIGHT_PROP_HEAL_ADD": "Healing Bonus",
    "FIGHT_PROP_HEALED_ADD": "Incoming Healing Bonus",
    "FIGHT_PROP_ELEMENT_MASTERY": "Elemental Mastery",
    "FIGHT_PROP_PHYSICAL_SUB_HURT": "Physical RES",
    "FIGHT_PROP_PHYSICAL_ADD_HURT": "Physical DMG Bonus",
    "FIGHT_PROP_FIRE_ADD_HURT": "Pyro DMG Bonus",
    "FIGHT_PROP_ELEC_ADD_HURT": "Electro DMG Bonus",
    "FIGHT_PROP_WATER_ADD_HURT": "Hydro DMG Bonus",
    "FIGHT_PROP_GRASS_ADD_HURT": "Dendro DMG Bonus",
    "FIGHT_PROP_WIND_ADD_HURT": "Anemo DMG Bonus",
    "FIGHT_PROP_ROCK_ADD_HURT": "Geo DMG Bonus",
    "FIGHT_PROP_ICE_ADD_HURT": "Cryo DMG Bonus",
    "FIGHT_PROP_FIRE_SUB_HURT": "Pyro RES",
    "FIGHT_PROP_ELEC_SUB_HURT": "Electro RES",
    "FIGHT_PROP_WATER_SUB_HURT": "Hydro RES",
    "FIGHT_PROP_GRASS_SUB_HURT": "Dendro RES",
    "FIGHT_PROP_WIND_SUB_HURT": "Anemo RES",
    "FIGHT_PROP_ROCK_SUB_HURT": "Geo RES",
    "FIGHT_PROP_ICE_SUB_HURT": "Cryo RES",
    "FIGHT_PROP_EFFECT_HIT": "Hit",
    "FIGHT_PROP_EFFECT_RESIST": "Resisted",
    "FIGHT_PROP_FREEZE_SHORTEN": "Frozen Duration Reduction",
    "FIGHT_PROP_DIZZY_SHORTEN": "Stunned Duration Reduction",
    "FIGHT_PROP_SKILL_CD_MINUS_RATIO": "CD Reduction",
    "FIGHT_PROP_SHIELD_COST_MINUS_RATIO": "Shield Strength",
    "FIGHT_PROP_CUR_HP": "HP",
    "FIGHT_PROP_MAX_HP": "Max HP",
    "FIGHT_PROP_CUR_ATTACK": "ATK",
    "FIGHT_PROP_CUR_DEFENSE": "DEF",
    "FIGHT_PROP_CUR_SPEED": "Movement SPD",
    "level": "Lv."
  }
}
