{
  "aetheranemo": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "sword"
  },
  "aetherdendro": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "sword"
  },
  "aetherelectro": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "sword"
  },
  "aethergeo": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "sword"
  },
  "albedo": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "sword"
  },
  "aloy": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "bow"
  },
  "amber": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "bow"
  },
  "ayaka": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "sword"
  },
  "ayato": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "sword"
  },
  "baizhu": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "catalyst"
  },
  "barbara": {
    "rarity": 4,
    "element": "hydro",
    "weapon_class": "catalyst"
  },
  "beidou": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "claymore"
  },
  "bennett": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "sword"
  },
  "candace": {
    "rarity": 4,
    "element": "hydro",
    "weapon_class": "polearm"
  },
  "chongyun": {
    "rarity": 4,
    "element": "cryo",
    "weapon_class": "claymore"
  },
  "collei": {
    "rarity": 4,
    "element": "dendro",
    "weapon_class": "bow"
  },
  "cyno": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "polearm"
  },
  "diluc": {
    "rarity": 5,
    "element": "pyro",
    "weapon_class": "claymore"
  },
  "diona": {
    "rarity": 4,
    "element": "cryo",
    "weapon_class": "bow"
  },
  "dori": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "claymore"
  },
  "eula": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "claymore"
  },
  "faruzan": {
    "rarity": 4,
    "element": "anemo",
    "weapon_class": "bow"
  },
  "fischl": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "bow"
  },
  "ganyu": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "bow"
  },
  "gorou": {
    "rarity": 4,
    "element": "geo",
    "weapon_class": "bow"
  },
  "heizou": {
    "rarity": 4,
    "element": "anemo",
    "weapon_class": "catalyst"
  },
  "hutao": {
    "rarity": 5,
    "element": "pyro",
    "weapon_class": "polearm"
  },
  "itto": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "claymore"
  },
  "jean": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "sword"
  },
  "kaeya": {
    "rarity": 4,
    "element": "cryo",
    "weapon_class": "sword"
  },
  "kazuha": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "sword"
  },
  "keqing": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "sword"
  },
  "klee": {
    "rarity": 5,
    "element": "pyro",
    "weapon_class": "catalyst"
  },
  "kokomi": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "catalyst"
  },
  "kuki": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "sword"
  },
  "layla": {
    "rarity": 4,
    "element": "cryo",
    "weapon_class": "sword"
  },
  "lisa": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "catalyst"
  },
  "lumineanemo": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "sword"
  },
  "luminedendro": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "sword"
  },
  "lumineelectro": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "sword"
  },
  "luminegeo": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "sword"
  },
  "mona": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "catalyst"
  },
  "nahida": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "catalyst"
  },
  "nilou": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "sword"
  },
  "ningguang": {
    "rarity": 4,
    "element": "geo",
    "weapon_class": "catalyst"
  },
  "noelle": {
    "rarity": 4,
    "element": "geo",
    "weapon_class": "claymore"
  },
  "qiqi": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "sword"
  },
  "raiden": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "polearm"
  },
  "razor": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "claymore"
  },
  "rosaria": {
    "rarity": 4,
    "element": "cryo",
    "weapon_class": "polearm"
  },
  "sara": {
    "rarity": 4,
    "element": "electro",
    "weapon_class": "bow"
  },
  "sayu": {
    "rarity": 4,
    "element": "anemo",
    "weapon_class": "claymore"
  },
  "shenhe": {
    "rarity": 5,
    "element": "cryo",
    "weapon_class": "polearm"
  },
  "sucrose": {
    "rarity": 4,
    "element": "anemo",
    "weapon_class": "catalyst"
  },
  "tartaglia": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "bow"
  },
  "thoma": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "polearm"
  },
  "tighnari": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "bow"
  },
  "venti": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "bow"
  },
  "wanderer": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "catalyst"
  },
  "xiangling": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "polearm"
  },
  "xiao": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "polearm"
  },
  "xingqiu": {
    "rarity": 4,
    "element": "hydro",
    "weapon_class": "sword"
  },
  "xinyan": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "claymore"
  },
  "yaemiko": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "catalyst"
  },
  "yanfei": {
    "rarity": 4,
    "element": "pyro",
    "weapon_class": "catalyst"
  },
  "yelan": {
    "rarity": 5,
    "element": "hydro",
    "weapon_class": "bow"
  },
  "yoimiya": {
    "rarity": 5,
    "element": "pyro",
    "weapon_class": "bow"
  },
  "yunjin": {
    "rarity": 4,
    "element": "geo",
    "weapon_class": "polearm"
  },
  "zhongli": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "polearm"
  },
  "travelerelectro": {
    "rarity": 5,
    "element": "electro",
    "weapon_class": "sword"
  },
  "traveleranemo": {
    "rarity": 5,
    "element": "anemo",
    "weapon_class": "sword"
  },
  "travelergeo": {
    "rarity": 5,
    "element": "geo",
    "weapon_class": "sword"
  },
  "travelerdendro": {
    "rarity": 5,
    "element": "dendro",
    "weapon_class": "sword"
  }
}
